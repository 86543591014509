import auth from '@/core/app/auth'
import { createRouter, createWebHistory } from 'vue-router'

import app from '@/core/app/app'
import ui from '@/core/app/ui';
import ParkingSelector from "@/features/parking/views/ParkingSelector.vue";

const metaSuper = {meta: {privilege: auth.privileges.SUPER}};
const metaAdminFeature = {meta: {privilege: auth.privileges.ADMIN}};
const metaAdmin = {meta: {privilege: auth.privileges.ADMIN, section: 'admin'}};
const metaBilling = {meta: {privilege: auth.privileges.ADMIN, section: 'admin'}};

const routes = [
  {
    path: '/',
    name: 'home',
    component: ParkingSelector
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

  {
    path: '/pruebas',
    name: 'pruebas',
    component: () => import( '../views/PruebasPage.vue')
  },


  { name: 'change-password', path:'/change-password',   component: () => import( '../views/ChangePasswordPage.vue')  },
  { name: 'tx-entry'   , path:'/super/tx-entry',           component: () => import( '@/super/txEntry/views/TxEntryListPage.vue') , ...metaSuper },
  { name: 'develTest'   , path:'/super/devel/test',           component: () => import( '@/super/devel/DevelTest.vue') , ...metaSuper },

  { name: 'login'   , path:'/login',               component: () => import( '@/features/public/LoginPage.vue') , meta: {layout:"public"} },
  { name: 'search'  , path:'/search',              component: () => import( '../core/views/SearchPage.vue')  },
  { name: 'error404', path:'/:pathMatch(.*)*',     component: () => import( '../views/errorPages/NotFound404.vue')  },
  { name: 'error403', path:'/forbidden',           component: () => import( '../views/errorPages/ForbiddenPage403.vue')  },
  {  path:'/:parking(\\d+)+',
     children: [
      { name: 'booking-new'    , path:'booking/new'  ,   component: () => import( '@/features/booking/views/NewBookingPage.vue')   },
      { name: 'booking-list'   , path:'booking/list/:listMode?' ,  component: () => import( '@/features/booking/views/BookingListPage.vue')  },


      { path:'booking/:id'  ,   component: () => import( '@/features/booking/views/BookingMainPage.vue'),
        children: [
          { path:'info'            ,   component: () => import( '@/features/booking/views/BookingInfoPage.vue')   },
          { path:'raw-info'            ,   component: () => import( '@/features/booking/views/BookingRawInfoPage.vue')   },
          { path:'change-status'     ,   component: () => import( '@/features/booking/views/BookingChangeStatusPage.vue')   },
          { path:'editor'            ,   component: () => import( '@/features/booking/views/BookingEditorPage.vue')   },
          { path:'invoices'          ,   component: () => import( '@/features/booking/views/BookingInvoicesPage.vue')   },
          { path:'history'          ,   component: () => import( '@/features/booking/views/BookingHistoryPage.vue')   },
        ]
      },
      {  path:'/:parking(\\d+)+',
      children: [
       { name: 'washes-list'   , path:'services/list/wash' ,  component: () => import( '@/features/booking/views/BookingWashesListPage.vue')  },
       { name: 'itv-list'   , path:'services/list/itv' ,  component: () => import( '@/features/booking/views/BookingItvListPage.vue')  },
       { name: 'mechanic-list'   , path:'services/list/mechanic' ,  component: () => import( '@/features/booking/views/BookingMechanicListPage.vue')  },
       { name: 'battery-list'   , path:'services/list/battery' ,  component: () => import( '@/features/booking/views/BookingBatteryListPage.vue')  },
      ]
    },


        { name: 'ops-summary'    , path:'ops/summary/:date?'  ,  component: () => import( '@/features/booking/views/ops/OpsSummaryPage.vue')  },
        { name: 'ops-ins'    , path:'ops/ins/:date?'  ,  component: () => import( '@/features/booking/views/ops/OpsInsPage.vue')  },
        { name: 'ops-outs'    , path:'ops/outs/:date?'  ,  component: () => import( '@/features/booking/views/ops/OpsOutsPage.vue')  },
        { name: 'ops-labels'    , path:'ops/labels/:date?'  ,  component: () => import( '@/features/booking/views/ops/OpsLabelsPage.vue')  },
        { name: 'ops-not-parked'    , path:'ops/not-parked'  ,  component: () => import( '@/features/booking/views/ops/NotParkedPage.vue')  },
        { name: 'ops-parked'    , path:'ops/parked'  ,  component: () => import( '@/features/booking/views/ops/ParkedPage.vue')  },
        { name: 'ops-for-out'    , path:'ops/for-out'  ,  component: () => import( '@/features/booking/views/ops/ParkedForOutPage.vue')  },
        { name: 'ops-not-paid'    , path:'ops/not-paid'  ,  component: () => import( '@/features/booking/views/ops/NotPaidPage.vue')  },
        { path:'ops/parking-status'           ,  component: () => import( '@/features/parking/views/ParkingStatusPage.vue')   },

        { path:'billing/invoice-list'           ,  component: () => import( '@/features/billing/views/InvoiceListPage.vue')  , ...metaBilling },
        { path:'billing/payment-list'           ,  component: () => import( '@/features/billing/views/PaymentListPage.vue')  , ...metaBilling },
        { path:'billing/report/:dateIni?/:dateFin?'  ,  component: () => import( '@/features/billing/views/ReportPage.vue')  , ...metaBilling },
        { path:'billing/invoice-creator'           ,  component: () => import( '@/features/billing/views/InvoiceCreator.vue')  , ...metaBilling },
        { path:'client/client-list'           ,  component: () => import( '@/features/client/views/ClientListPage.vue')   },
        { path:'client/:clientId/info'           ,  component: () => import( '@/features/client/views/ClientInfoPage.vue')   },
        { path:'user/user-list'           ,  component: () => import( '@/features/user/views/UserListPage.vue') , ...metaAdmin  },
        { path:'user/:userId/info'           ,  component: () => import( '@/features/user/views/UserInfoPage.vue') , ...metaAdmin  },
        { path:'client/vehicle-list'           ,  component: () => import( '@/features/vehicle/views/VehicleListPage.vue')   },
        { path:'client/contract-list'           ,  component: () => import( '@/features/contract/views/ContractListPage.vue')   },
        { path:'client/valet-pack-list'           ,  component: () => import( '@/features/valet-pack/views/ValetPackListPage.vue')   },

        { path:'admin/parking'                ,  component: () => import( '@/features/parking/views/AdminParkingPage.vue')  , ...metaAdmin },
        { path:'admin/automatic-cancellation' ,  component: () => import( '@/features/parking/views/AutomaticCancellationPage.vue')  , ...metaAdmin },
        //{ path:'admin/tolerance-minutes'      ,  component: () => import( '@/features/parking/views/ToleranceMinutesPage.vue')  , ...metaAdmin },
        // { path:'admin/price-calculation'      ,  component: () => import( '../views/admin/PriceCalculationPage.vue')  , ...metaAdmin },
        //{ path:'admin/user'                   ,  component: () => import( '../views/admin/users/UserAdminPage.vue')  , ...metaAdmin },
        { path:'admin/product'                   ,  component: () => import( '../features/product/views/ProductListPage.vue')  , ...metaAdmin },
        { path:'admin/product2'                   ,  component: () => import( '../features/product/components/edge/ProductListPageEdge.vue')  , ...metaAdmin },
        { path:'admin/coupon/list'                   ,  component: () => import( '../features/coupon/views/CouponListPage.vue')  , ...metaAdmin },
        { path:'admin/email-template' ,     component: () => import( '../features/mail/views/EmailTemplatePage.vue') , ...metaAdmin },
        { path:'admin/email-style'   ,     component: () => import( '../features/mail/views/EmailStylePage.vue') , ...metaAdmin },
        { path:'admin/service/list'   ,     component: () => import( '../features/service/views/ServiceListPage.vue') , ...metaAdmin },
        { path:'admin/service/tariff'   ,     component: () => import( '../features/service/views/ServiceTariffPage.vue') , ...metaAdmin },
        { path:'admin/billing-configuration'   ,     component: () => import( '../views/admin/billing/CompanyPage.vue') , ...metaAdmin },
        { path:'admin/billing-invoice-template'   ,     component: () => import( '../views/admin/billing/InvoiceTemplatePage.vue') , ...metaAdmin },

        { path:'account/user-report/:dateIni?/:dateFin?'  ,  component: () => import( '@/features/account/views/UserReportPage.vue') },
    ]
  },


  { name: 'smtp-cfg'       , path:'/super/smtp-cfg',           component: () => import( '../super/smtpcfg/views/SmtpCfgPage.vue') , ...metaSuper },
  //{ name: 'email-style'    , path:'/admin/email-style'   ,     component: () => import( '../views/admin/email/EmailStylePage.vue') , ...metaAdmin },



]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from) => {
    let hasToken = auth.hasTokenStored();


    if (!hasToken) {
        if (to.name !== 'login')
            return { name: 'login' }
        else return true;
    }


    if (to.name === 'login')
        return  hasToken ? {name: 'home' } : false;

    await app._requireSession();

    if (!auth.isLoggedIn())
        return { name: 'login' }

    if (to?.meta?.privilege)
        if (!auth.hasPrivilege(to.meta.privilege))
            return ({ name: 'error403' });

    if (to?.params?.parking) {
        let ok = await app.setParking(parseInt(to.params.parking[0]));
        if (!ok)
            return { name: 'home' };
    }

    if (to?.meta?.section)
        ui.mainMenuShowSection(to.meta.section);
});


export default router;
