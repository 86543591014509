import api from "@/core/app/api";

/**
 * @param {number} id
 */
async function get(id) {
    return await api.getId('product', id);
}

/**
 * @param {number} id
 * @param {string} name
 */
async function updateName(id, name) {
    return await api.post('product/update-name', {id, name});
}


/**
 * @param {string} endpoint
 * @param {object} data
 */
async function post(endpoint, data) {
    return await api.post('product/' + endpoint, data);
}

async function list(data) {
    return await api.post('product/get-list', data);
}

async function getById(id) {
    return await api.getById('product', id, '/get-for-admin');
}

export default {
    get, getById,
    post,
    list,
}
