import api from './api';
import app from './app';
import ui from "@/core/app/ui";
import tx from "@/core/app/tx";



const _auth = {
    token: null,
    tokenExp:0,
    isLoggedIn: false,
    privileges: {isSuper: false, items: {}},
    userId: null,
    name: null,
    username: null,
    parkings: {
        allAllowed: false,
        csv: null
    },
    hasTokenStored: false
}


function getData(){
    let data={};
    data.name=_auth.name;
    data.username=_auth.username;
    data.userId=_auth.userId;
    return data;
}

/**
 * @param{boolean}isSuper
 * @param {null|string}privilegesCSV
 */
function setPrivileges(isSuper, privilegesCSV) {
    _auth.privileges.isSuper = isSuper;
    _auth.privileges.items = {};

    clearLazy();

    if (!privilegesCSV) {
        return;
    }

    let items = privilegesCSV.split(',');
    for (let privilege of items)
        _auth.privileges.items[privilege] = true;

}


function isLoggedIn() {
    return _auth.isLoggedIn;
}

async function logIn(username, password) {
    let tokenInfo = await api.publicCall('POST', 'auth/login/staff', {username, password});
    if (!tokenInfo.ok)
        return;

    _auth.isLoggedIn = true;
    _auth.token = tokenInfo.data.token;
    _auth.tokenExp   = tokenInfo.data.exp*1000;
    //_auth.tokenExp   = new Date().getTime()+20*1000; mock
    _auth.hasTokenStored = true;
    localStorage.setItem('token', _auth.token);
    localStorage.setItem('token-exp', _auth.tokenExp);
    api.setToken(_auth.token);

    await getAuthorization();
    clearLazy();
}

async function refreshToken() {
    _auth.user = null;
    let tokenInfo = await api.call('POST', 'auth/refresh-token', null, false);

    if (!tokenInfo.ok)
        return;

    _auth.token = tokenInfo.data.token;
    _auth.tokenExp   = tokenInfo.data.exp*1000;
    //_auth.tokenExp   = new Date().getTime()+ 3*60*1000; //mock
    _auth.hasTokenStored = true;
    localStorage.setItem('token', _auth.token);
    localStorage.setItem('token-exp', _auth.tokenExp);
    api.setToken(_auth.token);
}



function logOut() {
    clearLazy();
    _auth.isLoggedIn = false;
    _auth.user = null;
    _auth.userId = null;
    _auth.name = null;
    _auth.username = null;
    _auth.hasTokenStored = false;
    setPrivileges(false, null);

    localStorage.removeItem('token');
    localStorage.removeItem('token-exp');
    app.setUsername('');
    api.setToken('');
}


/**
 * @param {string} privilege
 * @returns {boolean}
 */
function hasPrivilege(privilege) {
    return _auth.privileges.isSuper || (_auth.privileges.items[privilege] ?? false);
}

async function getAuthorization() {
    _auth.user = null;
    let userInfo = await api.call('POST', 'auth/get-authorization', null, false);

    if (!userInfo.ok) return;
    setPrivileges(userInfo.data.privileges.isSuper, userInfo.data.privileges.csv);

    _auth.name = userInfo.data.name;
    _auth.username = userInfo.data.username;
    _auth.userId = userInfo.data.userId;

    _auth.parkings.allAllowed = userInfo.data.parkings.allAllowed;
    _auth.parkings.csv = userInfo.data.parkings.csv;

    app.setUsername(_auth.username);
}


async function loadStoredSession() {
    clearLazy();
    _auth.isLoggedIn = false;
    _auth.token = localStorage.getItem('token');
    _auth.tokenExp = localStorage.getItem('token-exp');

    if (_auth.token) {
        api.setToken(_auth.token);
        await getAuthorization();
        if (!_auth.userId)
            logOut();
        else {
            _auth.isLoggedIn = true;
            _auth.hasTokenStored = true;
        }
    }
}

function hasTokenStored() {
    return _auth.hasTokenStored;
}

async function changePassword(password) {
    return await api.post('user/change-password', {userId: _auth.userId, password})
}

function isSuper() {
    return _auth.privileges.isSuper;
}


if (localStorage.getItem('token'))
    _auth.hasTokenStored = true;

const privileges = Object.freeze({
    SUPER: 'super',
    ADMIN: 'admin',
    BOSS: 'boss',
    EXPORT: 'export',
    EDIT_CHECKIN_DATES: 'edit-checkin-dates',
    EDIT_CHECKOUT_DATES: 'edit-checkout-dates',
    FORCE: 'force',
});


// Lazy user, user do not perform an operation in LAZY_USER_TIMEOUT ms
let timerForLazyUsers = null;
const LAZY_USER_TIMEOUT = 15*60*1000;

function logOutLazyUser() {
    if (_auth.privileges.isSuper || !_auth.isLoggedIn) {
        return;
    }
    clearTimeout(timerForLazyUsers);
    ui.sendToastDanger(tx.t("Disconnected due to inactive user"));
    logOut();
    document.location='/';
}

function clearLazy() {
    clearTimeout(timerForLazyUsers);
}
function keepNotLazy() {
    clearLazy();
    if (_auth.privileges.isSuper) {
        return;
    }
    timerForLazyUsers = setTimeout(logOutLazyUser, LAZY_USER_TIMEOUT);
}

const AUTH_WORKER_INTERVAL = 3*60*1000; //time between authWorker calls in ms
const TOKEN_REFRESH_MARGIN = 2*AUTH_WORKER_INTERVAL;
function authWorker(){
    if (!_auth.isLoggedIn) return;
    let now = new Date().getTime();

    if ((now+ TOKEN_REFRESH_MARGIN)>=_auth.tokenExp)
        refreshToken();
}

setInterval (authWorker, AUTH_WORKER_INTERVAL);


export default {
    isLoggedIn,
    logIn,
    logOut,
    hasPrivilege,
    hasTokenStored,
    loadStoredSession,
    changePassword,
    isSuper,
    privileges,
    keepNotLazy,
    getData

};
