import tx from "@/core/app/tx";

const values = Object.freeze({
    AVOID: 1,
    PROBLEMATIC: 10,
    WARNING: 50,
    NORMAL: 100,
    SPECIAL: 150,
    VIP: 200,
});


const items = [
    {id: 1, nameTx: 'Avoid', name: 'Avoid'},
    {id: 10, nameTx: 'Problematic', name: 'Problematic'},
    {id: 50, nameTx: 'Warning', name: 'Warning'},
    {id: 100, nameTx: 'Normal', name: 'Normal'},
    {id: 150, nameTx: 'Special', name: 'Special'},
    {id: 200, nameTx: 'VIP', name: 'VIP'},
];

function getName(value) {
    switch (value) {
        case 1   :
            return items[0].name;
        case 10  :
            return items[1].name;
        case 50  :
            return items[2].name;
        case 100 :
            return items[3].name;
        case 150 :
            return items[4].name;
        case 200 :
            return items[5].name;
        default:
            return value;
    }
}

function translateNames() {
    for (let item of items) {
        item.name = tx.t(item.nameTx);
    }
}

function getItems() {
    return items
}


export default {
    getItems, getName, translateNames, values
}

