import tx from "@/core/app/tx";

const items = [
    {id:0   , nameTx:'None'                    , name:'None'                     },
    {id:10  , nameTx:'Invoice Type Short Simplified'              , name:'Simplified'               },
    {id:20  , nameTx:'Invoice Type Short Full'                    , name:'Full'                     },
    {id:110 , nameTx:'Invoice Type Short Corrective Simplified'   , name:'Corrective Simplified'    },
    {id:120 , nameTx:'Invoice Type Short Corrective Invoice'      , name:'Corrective Invoice'       },
    {id:300 , nameTx:'Invoice Type Short Converted'               , name:'Converted'                },
];


const values = Object.freeze ({
    NONE                   : 0   ,
    SIMPLIFIED             : 100  ,
    FULL                   : 200  ,
    CORRECTIVE_SIMPLIFIED  : 310 ,
    CORRECTIVE_COMPLETE    : 320 ,
    CONVERTED              : 400 ,
});




function getName(value) {
    switch (value) {
        case values.NONE                   : return  items[0].name;
        case values.SIMPLIFIED             : return  items[1].name;
        case values.FULL                   : return  items[2].name;
        case values.CORRECTIVE_SIMPLIFIED  : return  items[3].name;
        case values.CORRECTIVE_COMPLETE    : return  items[4].name;
        case values.CONVERTED              : return  items[4].name;

        default: return value;
    }
}

function translateNames() {
    for (let item of items) {
        item.name = tx.t(item.nameTx);
    }
}

function getItems() { return items }


export default {
    getItems, getName, translateNames, values
}



