<template>
  <h1>{{ tx.t('Select parking') }}</h1>
  <div class="row gap">


    <router-link v-for="parking of parkings" :key="parking.id" :to='`/${parking.id}/booking/list`'
                 class="parking-card col">
      <img :src="`/images/parking/${parking.id}-${app.state.colorTheme}.png`">
      <div class="title">{{ parking.name }}</div>
    </router-link>
  </div>
</template>


<script setup>

import app from '@/core/app/app.js';

import tx from '@/core/app/tx.js';
import {ref} from 'vue';
import parkingApp from "@/features/parking/app/parkingApp";


const parkings = ref([]);

async function loadParkings() {
  let response = await parkingApp.getAll();
  parkings.value = response.rows;
}

loadParkings();

</script>
<style>
.parking-card {
  width: 15em;
  font-size: var(--font-size-5);
  text-align: center;
  transition: all 250ms linear;
  text-decoration: none;
  color: var(--ink);
  padding: .5em;
  background: var(--canvas);


}

.parking-card .title {

  padding: .5em 1em;

}

.parking-card:hover {
  color: var(--color-primary);


  /* scale: 1.03; */


  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 25px 20px -20px;
  box-shadow: hsla(0, 0%, 0%, 0.35) 0px 25px 20px -20px;
  box-shadow: hsla(216, 82%, 25%, 0.35) 0px 25px 20px -20px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px; */

}

.theme-dark .parking-card:hover {
  color: var(--color-primary);
  background: var(--canvas-100);
  box-shadow: 0;
}

</style>