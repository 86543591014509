import cfg from '../../app/cfg';
import ui from './ui';
import tempus from "@/core/app/tempus";
import auth from "@/core/app/auth";

let apiURL = '';


const headersPublic = {'Content-Type': 'application/json'};
const headersPrivate = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer Token'
};

function setLang(langCode) {
    apiURL = cfg.apiURL + '/' + langCode + '/';
}

function setToken(token) {
    headersPrivate.Authorization = 'Bearer ' + token;
}


async function publicCall(method, url, data = null) {
    let payload = {
        method,
        headers: headersPublic
    };

    if (data)
        payload.body = JSON.stringify(data);

    let response = await fetch(apiURL + url, payload);
    return await response.json();
}


async function call(method, url, data = null, mustToastErrors = true) {
    auth.keepNotLazy();

    let payload = {
        method,
        headers: headersPrivate
    };


    if (data)
        payload.body = JSON.stringify(data);

    let response = await fetch(apiURL + url, payload);
    
    let json = await response.json();
    console.log(json);

    if (!response.ok) {
        let properyName = '';
        let errorMsg = json?.error?.message;
        if (errorMsg) {
            properyName = json?.error?.property;
        } else{
            errorMsg = json?.error || response?.statusText;
        }


        json.errorMessage = errorMsg;

        if (mustToastErrors)
            ui.sendToastDanger(errorMsg, properyName);

        if (401 === response.status)
            ui.emitLogout();
    }

    return json;
}

/**
 * @param {String} method
 * @param {String}url
 * @param {boolean}mustToastErrors
 * @param data
 */
async function callRaw(method, url, data = null, mustToastErrors = true) {
    auth.keepNotLazy();
    let payload = {
        method,
        headers: headersPrivate
    };

    if (data)
        payload.body = JSON.stringify(data);

    return await fetch(apiURL + url, payload);
}

/**
 * @param {String}url
 */
async function postText(url, data = null) {
    auth.keepNotLazy();
    let payload = {
        method: 'POST',
        headers: headersPrivate
    };

    if (data)
        payload.body = JSON.stringify(data);

    let response = await fetch(apiURL + url, payload);
    if (!response.ok) return null;
    return await response.text();
}


/**
 * @param {String}url
 */
async function list(url, options = null) {

    return await call('POST', url + '/get-list', options);
}

/**
 * @param {String}url
 */
async function get(url) {
    return await call('GET', url);
}

/**
 * @param {String}url
 * @param {Number}id
 */
async function getId(url, id) {
    if (null === id) return null;
    return await call('POST', url + '/get', {"id": id});
}

/**
 * Returns object data of get call, null 202 or error
 * @param url
 * @param {number}id
 * @returns {Promise<*|null>}
 */
async function getById(url, id,urlSuffix ='/get') {
    if (null === id) return null;
    let response = await call('POST', url + urlSuffix, {"id": id});
    return (response.ok) ? response.data : null;

}

async function postEz(url, data=null) {
    let response = await call('POST', url , data);
    return (response.ok) ? response.data : null;
}

async function postOk(url, data=null) {
    let response = await call('POST', url  , data);
    return response.ok;
}


/**
 * @param {String}url
 */
async function update(url, data = null) {
    return await call('PATCH', url, data);
}

/**
 * @param {String}url
 */
async function create(url, data = null) {
    return await call('POST', url, data);
}

/**
 * @param {String}url
 */
async function post(url, data = null) {
    return await call('POST', url, data);
}

/**
 * @param {String}url
 * @param {Number}id
 */
async function deleteId(url, id) {
    return await call('DELETE', url, {id});
}

/**
 * @param {String}baseUrl
 * @param {Number}id
 */
async function getNotesAsString(baseUrl, id) {
    let notesResponse = await post(baseUrl + '/get-notes', {id});
    if (notesResponse.ok) {
        return notesToString(notesResponse?.data)
    }
    return "";
}

function notesToString(notesData) {
    let notes = '';
    if (!notesData?.length) return "";
    for (let note of notesData) {
        notes += tempus.dateTx(note.date) + "h " + note.who + "\n" + note.note + "\n\n";
    }
    return notes;
}


// return await call('GET', url+'/id/'+id );
//return await call('DELETE', url+'/id/'+id );

setLang(cfg.defaultLang);

export default {
    publicCall,
    call, callRaw, postText,
    setLang, setToken,
    get, getId, getById, list, update, create, post, deleteId,
    getNotesAsString, notesToString,
    postEz, postOk
};

