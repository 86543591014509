import tx from "./tx";

const items = [
    {id:10 , nameTx:'Every 24h'     , name:'Every 24h'     },
    {id:20 , nameTx:'Natural days'     , name:'Natural days'     },
];

const values = Object.freeze ({
    EVERY_24H    : 10,
    NATURAL_DAY  : 20
});

function getName(countMode) {
    switch(countMode) {
        case values.EVERY_24H:    return  items[0].name;
        case values.NATURAL_DAY:  return  items[1].name;
        default:return countMode;
    }
}

function translateNames() {
    for (let item of items) {
        item.name = tx.t(item.nameTx);
    }
}

function getItems() { return items }



export default {
    getItems, getName, translateNames, values
}



