import tx from "./tx.js";

function iconHTML (hintTx, iconClass, color) {
    if (!tx.t) return;
    let hint = tx.translate(hintTx);
    return `<span class='icon ${iconClass}'     title='${hint}' aria-label='${hint}' role="img"  style='color:${color};'></span>`;
}

const  custom =  {
    icon : iconHTML,
    cog      : function (hintTx='Config' , color='hsl(255,  55%, 46%)') { return iconHTML (hintTx, 'fa fa-cog '   , color); },
    cogs     : function (hintTx='Config' , color='hsl(255,  55%, 46%)') { return iconHTML (hintTx, 'fa fa-cogs '   , color); },
    email    : function (hintTx='Email'  , color='hsl( 39, 100%, 50%)') { return iconHTML (hintTx, 'fa fa-envelope '   , color); },
    send     : function (hintTx='Send'   , color='hsl(19,  100%, 48%)') { return iconHTML (hintTx, 'fa fa-paper-plane '   , color); },
    bolt     : function (hintTx='Action' , color='hsl( 39, 100%, 50%)') { return iconHTML (hintTx, 'fa fa-bolt'   , color); },
    euro     : function (hintTx='Action' , color='hsl( 39, 100%, 50%)') { return iconHTML (hintTx, 'fa fa-euro'   , color); },

    edit     : function (hintTx='Edit'    , color='hsl(208,  56%, 53%)') { return iconHTML (hintTx, 'ti-pencil'        , color); },
    calendar : function (hintTx='Calendar', color='hsl(255,  55%, 46%)') { return iconHTML (hintTx, 'fa fa-calendar'   , color); },
    car      : function (hintTx='Car'     , color='hsl(255,  55%, 46%)') { return iconHTML (hintTx, 'fa fa-car'   , color); },
    book     : function (hintTx='Book'    , color='hsl(305,92%,46%)') { return iconHTML (hintTx, 'ti-book'   , color); },
    clone    : function (hintTx='Clone'   , color='hsl(148,  26%, 58%)') { return iconHTML (hintTx, 'fa fa-clone'      , color); },
    remove   : function (hintTx='Delete'  , color='hsl(  0, 100%, 47%)') { return iconHTML (hintTx, 'ti-trash'         , color); },
    pdf      : function (hintTx='PDF'     , color='hsl(  0, 100%, 47%)') { return iconHTML (hintTx, 'fa fa-file-pdf-o' , color); },
    super    : function (hintTx='Super'   , color='hsl(272, 100%, 48%)') { return iconHTML (hintTx, 'fa fa-paw'        , color); },
    editEx   : function (hintTx='Edit EX' , color='hsl(272, 100%, 48%)') { return iconHTML (hintTx, 'ti-pencil'        , color); },
    tariffs  : function (hintTx='Tariffs' , color='hsl( 39, 100%, 50%)') { return iconHTML (hintTx, 'fa fa-eur'        , color); },
    info     : function (hintTx='Info'    , color='hsl(196, 100%, 48%)') { return iconHTML (hintTx, 'fa fa-info'          , color); },
    view     : function (hintTx='View'    , color='hsl(19,  100%, 48%)') { return iconHTML (hintTx, 'ti-eye'           , color); },
    users    : function (hintTx='Users'   , color='hsl(212,  80%, 42%)') { return iconHTML (hintTx, 'fa fa-users'      , color); },
    grid3    : function (hintTx='vari'    , color='hsl(300, 100%, 25%)') { return iconHTML (hintTx, 'ti-layout-grid3-alt'      , color); },
    grid2    : function (hintTx='vari'    , color='hsl(39,  100%, 50%)') { return iconHTML (hintTx, 'ti-layout-grid2-alt'      , color); },
    copy     : function (hintTx='copy'    , color='hsl(212,  80%, 42%)') { return iconHTML (hintTx, 'fa fa-copy'      , color); },
    target   : function (hintTx='Target'  , color='hsl(212,  80%, 42%)') { return iconHTML (hintTx, 'ti-target'      , color); },
    map      : function (hintTx='Map'     , color='hsl(330, 100%, 71%)') { return iconHTML (hintTx, 'ti-map-alt'      , color); },
    notes    : function (hintTx='Notes'   ) { return `<img src='/images/list/notes.png' alt='nt' title='${tx.t(hintTx)}'>`; },
    wash    : function (hintTx='Wash'   , color='hsl(148,  26%, 58%)') { return iconHTML (hintTx, 'fa fa-tint'      , color); },
    wrench    : function (hintTx='Wrench'   , color='hsl(148,  26%, 58%)') { return iconHTML (hintTx, 'fa fa-wrench'      , color); },
    battery    : function (hintTx='Battery'   , color='hsl(148,  26%, 58%)') { return iconHTML (hintTx, 'fa fa-baterry-full'      , color); },
}

function recalculate(){
    tableIcons.edit     = custom.edit    ();
    tableIcons.calendar = custom.calendar();
    tableIcons.clone    = custom.clone   ();
    tableIcons.remove   = custom.remove  ();
    tableIcons.pdf      = custom.pdf     ();
    tableIcons.super    = custom.super   ();
    tableIcons.editEx   = custom.editEx  ();
    tableIcons.tariffs  = custom.tariffs ();
    tableIcons.info     = custom.info    ();
    tableIcons.view     = custom.view    ();
    tableIcons.notes    = custom.notes   ();
    tableIcons.book    = custom.book   ();
    tableIcons.wash    =  custom.wash();
    tableIcons.wrench    =  custom.wrench();
    tableIcons.battery    =  custom.battery();
}



const tableIcons = {
    custom,
    edit     : custom.edit    (),
    calendar : custom.calendar(),
    clone    : custom.clone   (),
    remove   : custom.remove  (),
    pdf      : custom.pdf     (),
    super    : custom.super   (),
    editEx   : custom.editEx  (),
    tariffs  : custom.tariffs (),
    info     : custom.info    (),
    view     : custom.view    (),
    wash     : custom.wash    (),
    wrench   :  custom.wrench(),
    battery  :  custom.battery()
}


export default tableIcons;
export const tableIconsRecalculate = recalculate;