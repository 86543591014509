<template>
    <div id='topbar'>
        <div id="topbar-left">
            <button class="topbar-button hidden_md" @click="toggleMenuMobile" :title="tx.t('Menu')"><span     class="ti-menu"></span></button>
            <button class="topbar-button visible_md " @click="toggleMenuDesktop" :title="tx.t('Menu')"><span     class="ti-menu"></span></button>
            <router-link to="/" class="top-bar-logo  row align-items-center margin-r-l " ><img :alt="app.state.parking.name" :src="getLogo" ></router-link>

            <div class="top-bar-search row  align-items-center visible_ld gap-2px visible_sd" >
                <input class="width-13rem" type="text" :placeholder="tx.t('Search')"  v-model="searchWhat" @keyup.enter="search('number')">
                <button class="visible_sd " :title="tx.t('Search by') + ' # ' + tx.t('Booking')"
                    @click="search('number')"><span class="ti-search"><span>#</span></span></button>

<!--                <button class="width-2rem" :title="tx.t('Search by') + ' ' + tx.t('Plate')"                    @click="search('plate')"><span class="fa fa-car"></span></button>-->

<!--                <button  class="width-2rem" :title="tx.t('Search by') + ' '+ tx.t('email')"
                    @click="search('email')"><span class="ti-email"></span></button>
                 <button class="width-2rem" :title="tx.t('Search by') + ' '+ tx.t('Phone')"
                    @click="search('phone')"><span class="fa fa-phone"></span></button> -->

                <button class="width-2rem" :title="tx.t('Search by') + ' '+ tx.t('Client')"
                    @click="search('client')"><span class="ti-user"></span></button>
                    <button class="width-2rem" :title="tx.t('Search by') + ' '+ tx.t('Vehicle')"
                    @click="search('plate')"><span class="ti-car"></span></button>


            </div>
        </div>
        <div id="topbar-right">
            <router-park to="booking/new" class="topbar-button text-success" :title="tx.t('New Booking')"><span class="icon fa fa-plus"></span> </router-park>
            <button class="topbar-button visible_sd text-ink  " :title="tx.t('Print')" @click="print"><span
                    class="ti-printer icon"></span></button>
            <UserDropMenu />
        </div>
    </div>
</template>

<script setup>
import app from '@/core/app/app';
import tx from '@/core/app/tx';
import ui from '@/core/app/ui';
import { computed } from '@vue/reactivity';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import UserDropMenu from './UserDropMenu.vue';

const searchWhat = ref('');
const router = useRouter();

const print = () => {
    ui.print();
}

const toggleMenuMobile = () => {
    document.getElementById("acp-menu").classList.toggle('visible_md' );
}

const toggleMenuDesktop = () => {
    document.getElementById("acp-layout").classList.toggle('desktop-menu');
}

const search = (mode) => {
    let what = searchWhat.value;
    searchWhat.value = '';
    router.push({ name: 'search', query: { what, mode } });
}

const getLogo = computed(() => {

    let id = app.state.parking.id ?  app.state.parking.id : 0;
    return `/images/parking/${id}-topbar-${app.state.colorTheme}.png`;
});

</script>
