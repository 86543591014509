//import {Vue, createApp } from 'vue'
import {createApp } from 'vue'
import App from './App.vue'
import app from './core/app/app';
import router from './router'
import focus from './directives/focus';
import AInput from '@/core/components/AInput.vue';
import ASelect from '@/core/components/ASelect.vue';
import AButtonAccept from '@/core/components/AButtonAccept.vue';
import AAlertError from '@/core/components/AAlertError.vue';
import AFormRow from '@/core/components/AFormRow.vue';
import RouterPark from '@/components/RouterPark';
const vueApp = createApp(App);
vueApp.component('AInput', AInput);
vueApp.component('ASelect', ASelect);
vueApp.component('AButtonAccept', AButtonAccept);
vueApp.component('AAlertError', AAlertError);
vueApp.component('RouterPark', RouterPark);
vueApp.component('AFormRow', AFormRow);
vueApp.directive("focus", focus);
vueApp.use(router).mount('#app');
