let locale = undefined;

const formats = {
    digits: {year: 'numeric', month: '2-digit', day: '2-digit'},
    short: {year: 'numeric', month: 'long', day: 'numeric'},
    long: {year: 'numeric', month: 'long', day: 'numeric'},

    digits_week: {weekday: 'narrow', year: 'numeric', month: '2-digit', day: '2-digit'},
    short_week: {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric'},
    long_week: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}
};


function humanizedMinutes(minutes) {
    if (minutes < 1)
        return "0 min";

    let out = '';
    if (minutes > 59) out += `${Math.floor(minutes / 60)}h`;

    let min = minutes % 60;

    if (min > 0) {
        if (minutes > 59) out += ' ';
        out += `${min}min`;
    }

    return out;
}

let txFormat = 'd-m-Y';

function dateTx(isoDate, onlyDate = false,  showSeconds=false) {
    if (!isoDate)
        return '';
    const hourEnd = showSeconds ? 19:16;

    if ('d-m-Y' === txFormat) {

        return (isoDate.length > 10 && !onlyDate)
            ?  isoDate.substring(8, 10) + '-' + isoDate.substring(5, 7) + '-' + isoDate.substring(0, 4) + ' ' + isoDate.substring(11, hourEnd)
            : isoDate.substring(8, 10) + '-' + isoDate.substring(5, 7) + '-' + isoDate.substring(0, 4);
    } else {
        return (isoDate.length > 10 && !onlyDate)
            ? isoDate.substring(0, 10) + ' ' + isoDate.substring(11, hourEnd)
            : isoDate;
    }
}


function hhmm(isoDate) {
    if (!isoDate)
        return '';
    return isoDate.substring(11, 16);
}


function setTxFormat(format) {
    txFormat = format;
}

function setLocale(code) {
    locale = code;
}

function parseDateOrNow(dateString) {
    let dt = dateString
        ? new Date(dateString + ' 00:00')
        : new Date();

    if (isNaN(dt))
        dt = new Date();
    return dt;
}


function removeSeconds(isoDate) {
    if (isoDate.length > 16)
        return isoDate.substring(0, 16);

    return isoDate;
}


/** Extends Date,especially to work with local dates */
export class TempusDate extends Date {
    parse(value) {
        //avoid utc for only date with hh:mm
        if (10 === value.length)
            this.setTime(Date.parse(value + "T00:00:00"));

        else this.setTime(super.parse(value));
    }

    parseOrNow(value) {
        if (value) {
            this.parse(value);
            if (isNaN(this))
                this.setTime(Date.now());
        } else this.setTime(Date.now());
    }

    getClone() {
        return new TempusDate(this.getTime());
    }

    copyDate(date) {
        this.setTime(date.getTime());
    }

    addTime(time_ms) {
        this.setTime(this.getTime() + time_ms);
    }

    addDays(numDays) {
        this.setDate(this.getDate() + numDays);
    }

    addWeeks(numWeeks) {
        this.addDays(7 * numWeeks);
    }

    addYears(numYears) {
        let oldMonth = this.getMonth;
        this.setFullYear(this.getFullYear() + numYears);

        //fix leap years
        if (this.getMonth() < oldMonth)
            this.setDate(0);
    }

    addMonths(numMonths) {
        let oldDate = this.getDate;
        this.setMonth(this.getMonth() + numMonths);
        //fix shorter months
        if (this.getDate() < oldDate)
            this.setDate(0);
    }

    toISODate() {
        let month = this.getMonth() + 1;
        if (month < 10) month = '0' + month;
        let day = this.getDate();
        if (day < 10) day = '0' + day;
        return this.getFullYear() + '-' + month + '-' + day;
    }

    toHhMm() {
        let hours = this.getHours();
        if (hours < 10) hours = '0' + hours;
        let minutes = this.getMinutes();
        if (minutes < 10) minutes = '0' + minutes;
        return hours + ':' + minutes
    }

    toHhMmSs() {
        let seconds = this.getSeconds();
        if (seconds < 10) seconds = '0' + seconds;
        return this.toHhMm() + ':' + seconds;
    }

    toISO() {
        return this.toISODate() + "T" + this.toHhMm()
    };
    toDb() {
        return this.toISODate() + " " + this.toHhMm()
    };
    toISOss() {
        return this.toISODate() + "T" + this.toHhMmSs()
    };


    toTxString(format) {
        return this.toLocaleDateString(locale, format);
    }

    isLeapYear() {
        let year = this.getFullYear();
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }

    geMonthDays() {
        let month = this.getMonth() + 1;
        if (2 === month) return this.isLeapYear() ? 29 : 28;
        if (4 === month || 6 === month || 9 === month || 11 === month) return 30;
        return 31;
    };

}


function countDays(isNatural, dateIni, dateFin) {
    if (!isNatural) {
        return Math.max(0, Math.ceil((dateFin.getTime() - dateIni.getTime()) / 86400000));
    }
    let graceMinutes = 3*60;


    let ini = new Date(dateIni);
    let fin = new Date(dateFin);
    let minutesOfLastDay = fin.getHours()*60 + fin.getMinutes();
    ini.setHours(12, 0, 0);
    fin.setHours(11, 0, 0); //prevenir cambio hora -1h
    let numDays = Math.max(0, 1+Math.ceil((fin.getTime() - ini.getTime()) / 86400000));

    if (numDays>0 && minutesOfLastDay <= graceMinutes)
        numDays--;

    return  numDays;
}


export default {
    formats,

    humanizedMinutes,
    dateTx,
    hhmm,
    setTxFormat,
    setLocale,
    parseDateOrNow,
    removeSeconds,
    countDays

}

