import tx from "./tx";


function addOutclickableListener() {
    window.addEventListener('click', (event) => {
        let outClickables = document.getElementsByClassName("outclickable");
        if (outClickables.length > 0)

            for (let item of outClickables) {
                if (!item.contains(event.target)) {
                    item.dispatchEvent(new Event("outclick"));
                }
            }
    });
}

let currenId_prefix = 'uid_';
let currentId = 0;
let toastKey = 13;

function getUid() {
    if (Number.MAX_SAFE_INTEGER == currentId) {
        currenId_prefix += '_';
        currentId = 0;
    }
    currentId++;

    return "uid_" + currentId+"_";
}

/**
 * @param {string} type
 * @param {string} text
 * @return {void}
 */
function sendToast(type, text, subtext = '') {

    let toaster = document.getElementById('acp-toasts');
    let icon = '';
    toastKey++;
    switch (type) {
        case 'danger':
        case 'warning':
            icon = 'fa fa-exclamation';
            break;

        case 'success':
            icon = 'ti-check';
            break;
        case 'primary':
            icon = 'fa fa-info';
            break;

        case 'super':
        case 'radiant':
            icon = 'ti-info-alt';
            break;
    }

    toaster?.dispatchEvent(new CustomEvent("toast", {detail: {key: toastKey, type, text, icon, subtext}}));
}


function applyThemes(colorTheme, fontTheme) {
    document.body.classList.remove('theme-light', 'theme-dark',
        'theme-font-14px', 'theme-font-16px');
    document.body.classList.add('theme-' + colorTheme, 'theme-font-' + fontTheme);
}


function blurActiveElement() {
    document.activeElement.blur();
}


function dropdownFocus(event) {
    let button = event.target.closest('a,button');
    button.setAttribute('focus-date', Math.floor(new Date()));
}


const dropdownClick = (event) => {
    let button = event.target.closest('a,button');
    let dateFocus = parseInt(button.getAttribute('focus-date'));

    if (!dateFocus) return;
    let seconds = (new Date()) - dateFocus;

    if (seconds > 500) button.blur();
}

function bodyCursorProgress() {
    document.body.style.cursor = "progress";
}

function bodyCursorWait() {
    document.body.style.cursor = "wait";
}


function bodyCursorDefault() {
    document.body.style.cursor = "default";
}


function emitLogout() {
    document.getElementById("button-logout")?.click();
}


function mainMenuShowSection(section) {
    let sectionEl = document.getElementById("#main-menu-" + section);
    if (sectionEl)
        sectionEl.click();
}

function print() {
    let printMe = document.querySelector('iframe.print-me');

    if (printMe) {
        printMe.contentWindow.focus();
        printMe.contentWindow.print();
        return;
    }

    window.print();
}


function googlePopup(whatSearh) {
    let url = "https://www.google.com/search?q=" + encodeURIComponent(whatSearh);
    let desc = "Google Search";
    window.open(url, desc, "width=750,height=700,noopener,noreferrer");
    //window.open(url,desc, "popup,noopener,noreferrer,width=750,height=700,resizable,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes");

}

/*
async function base6 4Async = (blob, mimeType) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrlPrefix = `data:${mimeType};base64,`;
        const base64WithDataUrlPrefix = reader.result;
        const base64 = base64WithDataUrlPrefix.replace(dataUrlPrefix, '');
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

*/
/**
 * @param idSuffixes {string[]}
 * @returns {object]t  {{cid: string, ...sufix:cid+sufix}}
 */
function generateCids (idSuffixes=['id']) {
    let cid = getUid();
    let obj = {cid:cid};
    for (let $item of idSuffixes) {
        obj[$item] = cid+$item;
    }
    return obj;
}

/**
 * Adds cid property to the object.
 *
 * Fills properties  with cid+property_name.
 *
 * @param cidsToFill {object}
 */
function fillCids (cidsToFill) {
    let cid = getUid();
    for (let property in cidsToFill)
        cidsToFill[property] = cid + property;

    cidsToFill.cid = cid;
}


export default {
    addOutclickableListener,
    applyThemes,
    blurActiveElement,
    bodyCursorWait, bodyCursorProgress, bodyCursorDefault,
    dropdownClick, dropdownFocus,
    emitLogout,
    getUid, generateCids, fillCids,
    googlePopup,
    mainMenuShowSection,
    print,
    sedToastUpdated: () => {
        sendToast('success', tx.t('Updated'))
    },
    sedToastCreated: () => {
        sendToast('success', tx.t('Created'))
    },
    sendToast,
    sendToastDanger: (text, subtext = '') => {
        sendToast('danger', text, subtext)
    },
    sendToastSuccess: (text, subtext = '') => {
        sendToast('success', text, subtext)
    },
}