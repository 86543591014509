import api from "./api";
import cfg from "../../app/cfg";
import tempus from "./tempus";

const _tx = {
    keys: { 'Hello': "Hola" },
    locale: undefined,
    langCode: 'es',
    langCodeLoaded: null,
    loginKeys: {
        en: {
            username: 'User',
            password: 'Password',
            signIn: 'Sign In',
            errorLogin: 'Invalid user name or password',
            title:"Please sign in"
        },
        es: {
            username: 'Usuario',
            password: 'Contraseña',
            signIn: 'Iniciar sesión',
            errorLogin: 'Usuario o contraseña invalido',
            title:"Inicie sesión"
        },
        pt: {
            username: 'Usuário',
            password: 'palavra-passe',
            signIn: 'Iniciar sessão',
            errorLogin: 'Nome de usuário ou palavra-passe inválidos',
            title:"Por favor, inicie sessão"
        },
        gl: {
            username: 'Usuario',
            password: 'Sontrasinal',
            signIn: 'Iniciar sesión',
            errorLogin: 'Nome de usuario ou contrasinal non válido',
            title:"Inicia sesión"
        },
        ca: {
            username: 'Usuari',
            password:  'Contrasenya',
            signIn:'Inicia sessió',
            errorLogin: "Nom d'usuari o contrasenya incorrectes",
            title:"Inicieu sessió"
        },
        de: {
            username: 'Benutzer',
            password: 'Passwort',
            signIn: 'Einloggen',
            errorLogin: 'Ungültiger Benutzername oder Passwort',
            title:"Bitte einloggen"
        },
    }
}

const tx = {
        getLocale:function () { return _tx.locale;},
        getLangCode: function () {
            return _tx.langCode
        },

        translate: function (key) {
            if (!key) return '';
            if ('€'==key) return '€';
            return _tx.keys[key] ?? key;
        },

        plural: function (key) {
            return tx.translate(key + '_s');
        },

        pluralize: function (howMany, key) {
            return (howMany === 1 || howMany === -1 || howMany === 1.0 || howMany === '1')
                ? tx.translate(key)
                : tx.translate(key + '_s');
        },

        p: function (howMany, key) {
            return `${howMany} ${tx.pluralize(howMany, key)}`
        },

        traslateAndCapitalize(key) {
            if (!key) return '';
            if ('€'==key) return '€';

            let text = tx.translate(key);
            return text.charAt(0).toUpperCase() + text.slice(1);
        },

        question(text) {
            if ('es'===_tx.langCode)
                return "¿"+text+"?";
            return text+"?";
        },

        t: null,
        tc:null,
        bool (boolValue) {
            return tx.translate(boolValue? 'Yes':'Not');
        }
    };


//initialization

function sanitizeLangCode(langCode) {
    if (langCode in cfg.langs)
        return langCode;
    return cfg.defaultLang;
}


function setLang(langCode) {
    _tx.langCode = sanitizeLangCode(langCode);
    _tx.locale = cfg.locales[_tx.langCode];
    localStorage.setItem('lang',_tx.langCode);
    tempus.setTxFormat(('en'===_tx.langCode || 'de'===_tx.langCode)?'Y-m-d':'d-m-Y');
    tempus.setLocale(_tx.locale);
}


async function loadLang() {
    if (_tx.langCode === _tx.langCodeLoaded)
        return;

    try
    {
        let response=  await api.callRaw('POST','tx/get-lang');
        _tx.keys = await response.json();

        if (_tx.keys)
            _tx.langCodeLoaded = _tx.langCode;
        else console.log('TX Load Lang Error - '+ _tx.langCode);
    } catch(e) {
        console.log ('TX Load Lang Error - ',e);
    }
}


function getLoginTexts() {
    return _tx.loginKeys[_tx.langCode];
}


tx.t  = tx.translate;
tx.tc = tx.traslateAndCapitalize;

_tx.langCode = sanitizeLangCode(localStorage.getItem('lang')??cfg.defaultLang);


export default tx;
export const txLoadLang = loadLang;
export const txSetLang = setLang;
export const txGetLoginTexts = getLoginTexts;
