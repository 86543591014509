import api from "@/core/app/api";
import app from "@/core/app/app";
import cfg from "../../../app/cfg";
import {TempusDate} from "@/core/app/tempus";
import tx from "@/core/app/tx";
import parkingApp from "@/features/parking/app/parkingApp";
import bookingStatus from "@/features/booking/app/bookingStatus";
import {billingPreferences} from "@/features/billing/app/billing";


//const bookingEditableStruct = Object.freeze({
const bookingEditableStruct = Object.freeze({
        status: bookingStatus.values.BOOKED,
        originId: 1,
        clientId: 1,
        in: {
            date: '',
            flight: '',
            passengers: 0,
            type: 10

        },
        out: {
            date: '',
            flight: '',
            passengers: 0,
            type: 10,
            indeterminate:false
        },
        contractId:null,
        manualDates: false,
        parkingId: null,
        productId: null,
        parkSpaceId: null,
        srvMechanicId: null,
        srvItvId: null,
        srvItv2Id: null,
        srvBatteryId: null,
        srvWashId: null,
        name: {first: '', last: ''},
        email: '',
        phone: {value: '', humanized: ''},
        phoneExtra: {value: '', humanized: ''},
        lang: 'es',
        vehicle: {plate: {value: '', humanized: ''}, model: '', color: '', id: null, brand: ''},
        billingPreference: billingPreferences.SIMPLIFIED,
        billingAddress: {
            company: '',
            address: '',
            city: '',
            zip: '',
            nif: {value: '', type: 900},
            state: ''
        },
        invoice_country_code: '',
        identityCard: {value: '', type: 900},
        notes: ""
    }
);

const calculatorStruct = Object.freeze({

        total: "0.00",
        stay: {price: "0.00", productId: null, numDays: 0},
        in: {price: "0.00", type: null},
        out: {price: "0.00", type: 0},
        wash: {price: "0.00", serviceId: null},
        battery: {price: "0.00", serviceId: null},
        itv: {price: "0.00", serviceId: null},
        itv2: {price: "0.00", serviceId: null},
        mechanic: {price: "0.00", serviceId: null}
    }
);

const calculatorJson = JSON.stringify(calculatorStruct);

const getBlankCalculator = () => JSON.parse(calculatorJson);


function getBlankBooking() {
    let out = JSON.parse(JSON.stringify({
        ...bookingEditableStruct,
        parkingId: app.state.parking.id,
        lang: cfg.publicDefaultLang,
        productId: parkingApp.getFirstProductId()

    }));

    let dt = new TempusDate();
    dt.setMinutes(dt.getMinutes(), 0, 0);
    out.in.date = dt.toISO();
    out.out.date = out.in.date;
    return out;
}

const passengerSelectorItems = [{id: 1, name: '1 passenger'}];
const inOutTypeItems = [
    // {id: 0, name: ''},
    {id: 10, name: 'Shuttle'},
    {id: 20, name: 'Valet'},
    {id: 21, name: 'Valet24'},
    {id: 22, name: 'Valet 0'}
];

function getInOutTypeName(value) {
    switch (value) {
        case 10 :
            return 'shuttle';
        case 20 :
        case 21 :
        case 22 :
            return 'valet';
        default:
            return ''
    }
}

function generatePassengerSelectorItems() {
    let passengersTx = tx.t('passenger_s');
    passengerSelectorItems.length = 0;
    passengerSelectorItems.push({id: 0, name: `0 ${passengersTx}`});
    passengerSelectorItems.push({id: 1, name: "1 " + tx.t("passenger")});

    for (let i = 2; i <= 10; i++)
        passengerSelectorItems.push({id: i, name: `${i} ${passengersTx}`});
}


function onChangeLang() {
    generatePassengerSelectorItems();
}

/**
 * @param {Number}id
 */
async function getNotes(id) {
    let notes = await api.getNotesAsString('booking', id);
    if (notes) alert(notes);
}


async function getInvoices(bookingId) {
    let response = await api.post('booking/get-invoices', {id: bookingId});
    return response.data;
}

async function calculate(calculatorRequestDTO) {
    let response = await api.post('booking/calculate', calculatorRequestDTO);
    return response.data;
}

async function payAndCreateInvoice(bookingId, paymentMethodId) {
    return await api.post('booking/pay-and-create-invoice', {id: bookingId, paymentMethod: paymentMethodId});
}

async function payAndCreateInInvoice(bookingId, paymentMethodId) {
    return await api.post('booking/pay-and-create-in-invoice', {id: bookingId, paymentMethod: paymentMethodId});
}

async function payAndCreateServicesInvoice(bookingId, paymentMethodId, services) {
    return await api.post('booking/pay-and-create-services-invoice', {id: bookingId, paymentMethod: paymentMethodId, services});
}


async function sendEmail(bookingId, toEmail) {
    return await api.post(`booking/send-email`, {id: bookingId, to: toEmail});
}

async function setParkSpace(bookingId, parkSpaceId) {
    return await api.post('booking/set-park-space', {id: bookingId, parkSpaceId});
}

async function checkCoupon(code, parkingId, productId, clientId) {
    let response =  await api.post('coupon/check', {code, parkingId, productId, clientId});
    return response.data;
}

export default {
    onChangeLang,
    getBlankBooking, bookingEditableStruct,
    getPassengerSelectorItems: () => passengerSelectorItems,
    getInOutTypeItems: () => inOutTypeItems,
    getInOutTypeName,
    getNotes,
    getInvoices,
    payAndCreateInvoice,
    payAndCreateInInvoice,
    payAndCreateServicesInvoice,
    getBlankCalculator,
    setParkSpace,
    calculate,
    checkCoupon
}

