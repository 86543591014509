import api from "@/core/app/api";
import tx from "@/core/app/tx";
import productApi from "@/features/product/app/productApi";
import parkingApi from "@/features/parking/app/parkingApi";


let parkingId = 0
let parkings = [];

const blankCache = {
    /** Normal and contract products */
    productsAll: null,

    /** Normal products, not contracts */
    normalProducts: null,

    /** Contract Products */
    contractProducts: null,
    contractProductsWithPrices: null,
    /** Products and contract names */
    productNames: [],

    services: null,
    parkZones: null
}

let cache = {...blankCache};

async function getAll() {
    if (parkings.length > 0)
        return parkings;

    let response = await parkingApi.list();
    parkings = response.data;
    return parkings;
}

function setIdParking(id) {
    if (id === parkingId) return;
    parkingId = id;
    cache = {...blankCache};
}


async function getProductsFromApi(filters = {}) {
    return await productApi.list({
        columns: ["id", "name", 'contract_price'],
        row_count: 0,
        count_rows: false,
        order: {'sort': 'asc'},
        filters: {"parkingId": parkingId, ...filters}
    });
}

async function loadProductsCache() {
    let allResponse = await getProductsFromApi();
    let normalResponse = await getProductsFromApi({isContract: false});
    let contractsResponse = await getProductsFromApi({isContract: true});

    if (allResponse.ok) {
        cache.productsAll = allResponse.data.rows;
        for (let product of cache.productsAll)
            cache.productNames["id" + product.id] = product.name;
    }

    if (normalResponse.ok)
        cache.normalProducts = normalResponse.data.rows;

    if (contractsResponse.ok) {
        cache.contractProducts = contractsResponse.data.rows;
        calculateContractProductsWithPrices();

    }


}

async function loadProductsCacheIfRequired() {
    if (cache.productsAll === null)
        await loadProductsCache();
}

async function getNormalProducts() {
    await loadProductsCacheIfRequired();
    return cache.normalProducts;
}


async function getAllProducts() {
    await loadProductsCacheIfRequired();
    return cache.productsAll;
}

async function getContractProducts() {
    await loadProductsCacheIfRequired();
    return cache.contractProducts;
}


async function getContractProductsNamesWithPrices() {
    await loadProductsCacheIfRequired();
    return cache.contractProductsWithPrices;
}

function calculateContractProductsWithPrices() {
    if (cache.contractProducts === null) {
        cache.contractProductsWithPrices = [];
        return;
    }

    cache.contractProductsWithPrices = [...cache.contractProducts];
    for (const contract of cache.contractProductsWithPrices) {
        contract.title = contract.name;
        contract.name = combineNameAndPrice(contract.title+' ', ' '+contract.contract_price);
    }

}


function combineNameAndPrice(name, price, length = '50', spacer = '.',) {
    const priceCharWidth = 10;
    return name.padEnd(length - priceCharWidth, spacer)
        + price.padStart(priceCharWidth - 2, spacer)
        + ' €';

}


function getFirstProductId() {
    return cache.normalProducts ? cache.normalProducts[0].id : null;
}

async function getServices() {
    if (!cache.services) {
        let response = await api.post('service/get-services-for-parking', {id: parkingId});
        if (response.ok) {
            cache.services = response.data;
            //for (let product of cache.products)                cache.servicesNames["id" + product.id] = product.name;
        }
    }
    return cache.services;
}


async function getParkZones() {
    if (cache.parkZones === null) {
        let response = await parkingApi.get(parkingId);
        if (response.ok) {
            cache.parkZones = response.data.parkZones;
        }
    }
    return cache.parkZones;
}

/**
 * @param {int} zoneId
 */
async function getParkZoneSpaces(zoneId) {
    let response = await api.post('park-zone/get-spaces', {id: zoneId});

    if (response.ok) {
        return response.data;
    }
    return null;
}


async function apiGet() {
    return await parkingApi.get(parkingId);
}

async function apiUpdate(data) {
    return await api.update('parking', {...data, id: parkingId});
}

/**
 * @param {int} productId
 */
async function getProductName(productId) {

    if (cache.productNames['id' + productId])
        return cache.productNames['id' + productId];
    let response = await api.getId('product', productId);
    if (response.ok)
        return response.data.name;

    return tx.t('Product') + ' ' + productId;
}


export default {
    getId: () => parkingId,
    getAll,
    getNormalProducts, getAllProducts, getContractProducts, getProductName,
    setIdParking, getContractProductsNamesWithPrices,
    apiGet, apiUpdate,
    getParkZones, getParkZoneSpaces,
    getServices, getFirstProductId
}
