import tx from "@/core/app/tx";

const items = [
    //{id:0  , nameTx:'New Boooking'  , name:'New Booking'     }, estado interno
    {id:1  , nameTx:'Cancelled'  , name:'Cancelled'                , criteria: {eq: 1 }      },
  //  {id:10 , nameTx:'Pre-booked' , name:'Pre-booked'               , criteria: {eq: 10}      },
    {id:20 , nameTx:'Booked'     , name:'Booked'                   , criteria: {eq: 20}      },
    // {id:30 , nameTx:'Check-in'   , name:'Cche-in'
    {id:40 , nameTx:'Inside'     , name:'Inside'                   , criteria: {eq: 40}      },
    {id:50 , nameTx:'Check Out'  , name:'Check Out'                , criteria: {eq: 50}      }, //proceso de checkout
    {id:60 , nameTx:'Finalized'  , name:'Finalized'                , criteria: {eq: 60}      },
   // {id:1000,   nameTx:'All + cancelled'  , name:'All + cancelled' , criteria: {gt: 0}     }
];
const itemsForList = [
    { id: 1000, nameTx: 'All + cancelled', name: 'All + cancelled', criteria: { gt: 0 } },
    { id: 0, nameTx: 'All', name: 'All', criteria: { gt: 1 } },
    { id: 1, nameTx: 'Cancelled', name: 'Cancelled', criteria: { eq: 1 } },
    { id: 20, nameTx: 'Booked', name: 'Booked', criteria: { eq: 20 } },
    // {id:30 , nameTx:'Check-in'   , name:'Cche-in'
    { id: 40, nameTx: 'Inside', name: 'Inside', criteria: { eq: 40 } },
    { id: 50, nameTx: 'Check Out', name: 'Check Out', criteria: { eq: 50 } }, //proceso de checkout
    { id: 60, nameTx: 'Finalized', name: 'Finalized', criteria: { eq: 60 } },

  
];
const values = Object.freeze ({
    // NEW  : 0 , Uso interno
    CANCELLED  : 1 ,
    PREBOOKED  : 10,
    BOOKED     : 20,
    //CHECK_IN: 30 Reservada futuro
    INSIDE     : 40,
    CHECK_OUT  : 50,
    FINALIZED  : 60
});

function getName(status) {
    switch(status) {
        case values.CANCELLED  : return  items[0].name;
        case values.BOOKED     : return  items[1].name;
        case values.INSIDE     : return  items[2].name;
        case values.CHECK_OUT  : return  items[3].name;
        case values.FINALIZED  : return  items[4].name;
        default:return status;
    }
}

function translateNames() {
    for (let item of items) {
        item.name = tx.t(item.nameTx);
    }
}


function translateNamesForList() {
    for (let item of itemsForList) {
        item.name = tx.t(item.nameTx);
    }
}

function getItems() { return items }

function getItemsForList(){return itemsForList}

export default {
    getItems,getItemsForList, getName,translateNamesForList, translateNames, values
}

