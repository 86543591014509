import api from "@/core/app/api";
import tx from "@/core/app/tx";

/**
 * @param {number} id
*/
async function get(id) {
    return await api.getId('parking', id);
}
/**
 * @param {number} id
 */
async function getSpaceById(id){
    return await api.getById('park-space', id);
}
/**
 * @param {number} id
 * @param {string} name
 */
async function updateName(id, name) {
    return await api.post('parking/update-name', {id,name });
}


/**
 * @param {string} endpoint
 * @param {object} data
 */
async function post(endpoint, data) {
    return await api.post('parking/'+endpoint, data);
}

async function list(data) {
    return await api.post('parking/get-list', data);
}


export default {
    get,
    updateName,
    post,
    list,
    getSpaceById
}
