<template>
<nav id="acp-menu" :lang="app.state.lang" class="  visible_md cute-scroll-bar padding-tb-l" @click="hideMenu_md" >
    <div class="acp-menu-content cute-scroll-bar"    >
    <div class="  top-bar-search row  flex-nowrap align-items-center hidden_ld  padding-lr-m padding-b-m gap-2px" >
                <input class="flex-grow-2" type="text" v-model="searchWhat" @keyup.enter="search" :placeholder="tx.t('Search')">


 <button class="visible_sd " :title="tx.t('Search by') + ' # ' + tx.t('Booking')"
                    @click="search('number')"><span class="ti-search"><span>#</span></span></button>

<!--                <button class="visible_sd " :title="tx.t('Search by') + ' ' + tx.t('Plate')"                    @click="search('plate')"><span class="fa fa-car"></span></button>-->


        <button class="visible_sd " :title="tx.t('Search by') + ' ' + tx.t('Client')"
                @click="search('client')"><span class="ti ti-user"></span></button>

            </div>

        <div    >
            <div class='spacer hidden_sd'></div>
            <button class="section" panel="bookings" @click="expandPanel" id="main-menu-bookings">{{tx.t('booking_s')}} </button>
            <div >
                <router-park to="booking/new" class="success" ><span class="icon fa fa-plus"></span> <span>{{tx.t('New Booking')}}</span>           </router-park>
                <router-park to="booking/list/current" ><span class="icon ti-book"></span> <span>{{tx.t('booking_s')}}</span>           </router-park>
<!--                <router-park to="booking/list/recent" ><span class="icon ti-home"></span> <span>{{tx.t('Recent')}}</span>           </router-park>-->
<!--                <router-park to="booking/list/inside" ><span class="icon ti-car"></span> <span>{{tx.t('Inside')}}</span>           </router-park>-->
            </div>
        </div>
       <div   >
            <div class='spacer'></div>
            <button class="section" panel="Services" @click="expandPanel" id="main-menu-services">{{tx.t('service_s')}} </button>
            <div >
                <router-park to="services/list/wash"  class="text-primary" ><span class="icon fa fa-tint text-primary"></span> <span>{{tx.t('Wash')}}</span>           </router-park>
                <router-park to="services/list/itv"   class="text-primary"><span class="icon fa fa-car"></span> <span>{{tx.t('Itv')}}</span>           </router-park>
                <router-park to="services/list/mechanic"  class="text-primary" ><span class="icon fa fa-wrench"></span> <span>{{tx.t('Mechanic')}}</span>           </router-park>
                <router-park to="services/list/battery"  class="text-primary" ><span class="icon fa fa-battery"></span> <span>{{tx.t('Battery')}}</span>           </router-park>
            </div>
        </div>
       <div   >
            <div class='spacer'></div>
            <button   panel="operations" @click ="expandPanel"    class="section" id="main-menu-operations" ><span>{{tx.t('Operations')}}</span></button>
            <div  class="collapse-content">
                <!-- <div class="section" @click.stop="expandPanel('operations')"></div><div class="section">{{tx.t('Operations')}}</div> -->
                <router-park to="ops/summary" ><span class="icon fa fa-binoculars"></span> <span>{{tx.t('Summary')}}</span>           </router-park>
                <router-park to="ops/ins" ><span class="icon ti-import"></span> <span>{{tx.t('INs')}}</span>           </router-park>
                <router-park to="ops/outs" ><span class="icon ti-export"></span> <span>{{tx.t('OUTs')}}</span>           </router-park>
                <router-park to="ops/not-parked"  class="text-info"  ><span class="icon fa fa-snowflake-o text-info"></span> <span>{{tx.t('Not Parked')}}</span>           </router-park>
                <router-park to="ops/parked"      class="text-primary" ><span class="icon ti-pinterest text-primary"></span> <span>{{tx.t('Parked')}}</span>           </router-park>
                <router-park to="ops/for-out"      class="text-purple" ><span class="icon ti-layout-media-left  text-purple"></span> <span>{{tx.t('For OUT')}}</span>           </router-park>
                <router-park to="ops/parking-status" ><span class="icon fa  fa-braille"></span> <span>{{tx.t('Parking Status')}}</span>           </router-park>
                <router-park to="ops/not-paid"    class="text-danger"  ><span class="icon ti-alert text-danger"></span> <span>{{tx.t('Not Paid')}}</span>           </router-park>
<!--                <router-park to="ops/labels" ><span class="icon fa fa-tags"></span> <span>{{tx.t('Labels')}}</span>           </router-park>-->
                <!-- <router-park to="ops/hours" ><span class="icon fa fa-clock-o"></span> <span>{{tx.t('Hours')}}</span>           </router-park> -->
            </div>
        </div>
<!--

        <div class="primary" v-if="auth.hasFeature('admin')">
            <div class='spacer'></div>
            <button class="section"  panel='availability' @click="expandPanel"  id="main-menu-admin">{{tx.t('Availability')}}</button>
            <div class="collapse-content">

                <router-park to="availability/groups" ><span class="icon ti-home"></span> <span>{{tx.t('Availability Group_s')}}</span>           </router-park>
                <router-park to="availability/groups" ><span class="icon ti-home"></span> <span>{{tx.t('Availability Calendar')}}</span>           </router-park>


            </div>
        </div>
 -->

        <div class="primary" v-if="isAdmin && app.state.parking.id">
            <div class='spacer'></div>
            <button class="section"  panel='billing' @click="expandPanel"  id="main-menu-billing">{{tx.t('Billing')}}</button>
            <div class="collapse-content">
               <router-park to="billing/invoice-list" ><span class="icon fa fa-file-text-o"></span> <span>{{tx.t('Invoice List')}}</span>           </router-park>
               <router-park to="billing/payment-list" ><span class="icon fa fa-euro"></span> <span>{{tx.t('Payment_s')}}</span>           </router-park>
               <router-park to="billing/report" ><span class="icon fa fa-bar-chart"></span> <span>{{tx.t('Payment Report')}}</span>           </router-park>
             
            <!--   <router-park to="billing/invoice-creator" ><span class="icon fa fa-euro"></span> <span>{{tx.t('Custom Invoice')}}</span>           </router-park> -->
<!--                <router-park to="admin/billing-configuration"    ><span class="icon ti-settings"></span> <span>{{tx.t('Configuration')}}</span>           </router-park>-->
<!--                <router-park to="admin/billing-invoice-template"    ><span class="icon ti-ruler-pencil"></span> <span>{{tx.t('Invoice Template_s')}}</span>           </router-park>-->


            </div>
        </div>
        <div class="primary"  v-if="app.state.parking.id" >
            <div class='spacer'></div>
            <button class="section"  panel='client' @click="expandPanel"  id="main-menu-client">{{tx.t('Client_s')}}</button>
            <div class="collapse-content">
                <router-park to="client/client-list" ><span class="icon fa fa-user-o"></span> <span>{{tx.t('Client_s')}}</span>           </router-park>
                <router-park to="client/vehicle-list" ><span class="icon fa fa-car"></span> <span>{{tx.t('Vehicle_s')}}</span>           </router-park>
                <router-park  to="client/contract-list"   ><span class="icon fa fa-ticket"></span> <span>{{tx.t('Contract_s')}}</span>           </router-park>
<!--                <router-park  to="client/valet-pack-list" v-if="isBoss" ><span class="icon fa fa-diamond"></span> <span>{{tx.t('Valet pack_s')}}</span>           </router-park>-->
            </div>
        </div>
        <div class="primary"  v-if="app.state.parking.id" >
            <div class='spacer'></div>
            <button class="section"  panel='user' @click="expandPanel"  id="main-menu-user">{{tx.t('User_s')}}</button>
            <div class="collapse-content">
                <router-park to="user/user-list" ><span class="icon fa fa-user-o"></span> <span>{{tx.t('User List')}}</span> </router-park>
            </div>
        </div>


<!--        <div class="primary"  v-if="app.state.parking.id && isBoss">-->
<!--            <div class='spacer'></div>-->
<!--            <button class="section"  panel='contracts' @click="expandPanel"  id="main-menu-billing">{{tx.t('Contract_s')}}</button>-->
<!--            <div class="collapse-content">-->
<!--                <router-park  to="client/contract-list" v-if="isBoss" ><span class="icon fa fa-ticket"></span> <span>{{tx.t('Contract List')}}</span>           </router-park>-->
<!--            </div>-->
<!--        </div>-->

        <!--
      <div class="primary"    v-if="app.state.parking.id">
        <div class='spacer'></div>
        <button class="section"  panel='vehicle' @click="expandPanel"  id="main-menu-billing">{{tx.t('Parking')}}</button>
        <div class="collapse-content">


        </div>
      </div>
      -->
        <div class="primary" v-if="isAdmin">
            <div class='spacer'></div>
            <button class="section"  panel='admin' @click="expandPanel"  id="main-menu-admin">Admin</button>
            <div class="collapse-content">
                <router-park to="admin/coupon/list" ><span class="icon fa fa-gift"></span> <span>{{tx.t('Coupon_s')}}</span>           </router-park>
                <router-park to="admin/parking" ><span class="icon ti-home"></span> <span>{{tx.t('Parking')}}</span>           </router-park>
                <router-park to="admin/automatic-cancellation" ><span class="icon fa fa-ban"></span> <span>{{tx.t('Automatic Cancellation')}}</span>           </router-park>
                <router-park to="admin/email-template" ><span class="icon fa fa-envelope-o"></span> <span>{{tx.t('Email Template_s')}}</span>           </router-park>
                <router-park to="admin/email-style"    ><span class="icon fa fa-envelope-o"></span> <span>{{tx.t('Email Style_s')}}</span>           </router-park>
                <router-park to="admin/service/tariff"  v-if="isBoss"  ><span class="icon fa fa-eur"></span> <span>{{tx.t('Services tariff')}}</span>           </router-park>
                <router-park to="admin/product"  v-if="isBoss"><span class="ico fa fa-cubes"></span> <span>{{tx.t('product_s')}}</span>           </router-park>

<!--                <router-park to="admin/user" ><span class="icon fa fa-users"></span> <span>{{tx.t('User_s')}}</span>           </router-park>-->
<!--                <router-park to="admin/price-calculation" ><span class="icon fa fa-calculator"></span> <span>{{tx.t('Price Calculation')}}</span>           </router-park>-->
<!--                <router-park to="admin/tolerance-minutes" ><span class="icon ti-timer"></span> <span>{{tx.t('Tolerance minutes')}}</span>           </router-park>-->
            </div>
        </div>


        <div class="super" v-if="auth.isSuper()">
        <div class='spacer'></div>
            <button class="section" panel="super" @click="expandPanel"  >Super</button>
            <div class="collapse-content">
                <router-park to="admin/service/list"    ><span class="icon fa fa-cubes"></span> <span>{{tx.t('Service_s')}}</span>           </router-park>
                <router-link to="/super/smtp-cfg" ><span class="icon fa fa-envelope-o"></span> <span>SMTP Cfg</span></router-link>
                 <router-link to="/super/tx-entry" ><span class="icon fa fa-language"></span> <span>Tx Translations</span></router-link>
                 <router-link to="/super/devel/test" ><span class="icon ti-ruler-pencil"></span> <span>Devel Test</span></router-link>
            </div>
        </div>
        <div  class="primary" v-if="app.state.parking.id">
        <div class='spacer'></div>
            <button class="section" panel="My operations" @click="expandPanel"  >{{tx.t('My account')}}</button>
            <div class="collapse-content">
                <router-park to="account/user-report" ><span class="icon fa fa-bar-chart"></span> <span>{{tx.t('My operations')}}</span>           </router-park>
            </div>
        </div>
    
    </div>
</nav>
</template>

<script setup>
import auth from '@/core/app/auth';
import app from '@/core/app/app';
import tx from '@/core/app/tx';


import { ref } from 'vue';
import { useRouter } from 'vue-router';


const searchWhat = ref('');
const router = useRouter();
const currentPanel=ref('bookings');
const hideMenu_md = (event) => {
    if (event && event.target.id!="acp-menu") {
        let target =  event.target.closest('.collapse-content a, .collapse-content button');
        if (!target) return;
    }

    document.getElementById("acp-menu").classList.add('visible_md');
}

const search = (mode) => {
    let what = searchWhat.value;
    searchWhat.value = '';
    hideMenu_md(null);
    router.push({ name: 'search', query: { what, mode } });

}

let lastPanelEl = null ;

const expandPanel=( event ) =>{
    let el = event.target.closest('#acp-menu a, #acp-menu button');
    hideCurrentPanel();
    currentPanel.value = el.getAttribute("panel");;
    showPanel(el);
}

const  hideCurrentPanel = () => {
    if (!lastPanelEl) {
        lastPanelEl = getEl(currentPanel.value);
    }

    let content = lastPanelEl.nextElementSibling;
    content.classList.add('collapse-content');
    content.style.maxHeight = "0px";
    lastPanelEl.classList.remove('collapse-open');
}

function showPanel(el) {
    lastPanelEl = el;
    el.classList.add('collapse-open');
    let content = el.nextElementSibling;
    content.style.maxHeight = content.scrollHeight + "px";
}


function getEl(panelName) {
    console.log(panelName);
    console.log(`#acp-menu [panel="${panelName}"]`);
    return document.querySelector(`#acp-menu [panel="${panelName}"]`);
}


let isAdmin = auth.hasPrivilege(auth.privileges.ADMIN);
let isBoss  = auth.hasPrivilege(auth.privileges.BOSS);

</script>