import { nextTick } from "vue";

//TODO best solution
function focusEl(el,maxAttempts, timeBetweenAttempts) {
    try {el.focus();} catch(e){};
    if (document.activeElement !== el  && maxAttempts>1)
        setTimeout(()=> {focusEl(el,maxAttempts-1, timeBetweenAttempts);}, timeBetweenAttempts);
}


export default {
    mounted:  (el)=> {
        focusEl(el, 4 , 200);
    }
};