import tx from "./tx";

const items = [
    {id:100 , nameTx:'Web'     , name:'Web'     },
    {id:200 , nameTx:'App'     , name:'App'     },
    {id:300 , nameTx:'Phone'   , name:'Phone'   },
    {id:400 , nameTx:'email'   , name:'email'   },
    {id:500 , nameTx:'Walk-in' , name:'Walk-in' },
    {id:600 , nameTx:'Reseller', name:'Reseller'},
    {id:700 , nameTx:'Agency'  , name:'Agency'  },
    {id:900 , nameTx:'Other'   , name:'Other'   }
];

const values = Object.freeze ({
    WEB     : 100,
    APP     : 200,
    PHONE   : 300,
    EMAIL   : 400,
    WALK_IN : 500,
    RESELLER: 600,
    AGENCY  : 700,
    OTHER   : 900
});


function getName(idVia) {
    switch (idVia) {
        case values.WEB     : return  items[0].name;
        case values.APP     : return  items[1].name;
        case values.PHONE   : return  items[2].name;
        case values.EMAIL   : return  items[3].name;
        case values.WALK_IN : return  items[4].name;
        case values.RESELLER: return  items[5].name;
        case values.AGENCY  : return  items[6].name;
        case values.OTHER   : return  items[7].name;
        default: return idVia;
    }
}

function translateNames() {
    for (let item of items) {
        item.name = tx.t(item.nameTx);
    }
}

function getItems() { return items }


export default {
    getItems, getName, translateNames, values
}



