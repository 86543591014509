<template>
    <fieldset  :lang="app.state.lang" :class="fieldsetCssClass" >
        <label v-if="props.labelTx"   :for="id"   class="label-s text-upper-1st">{{tx.t(props.labelTx) }}</label>

        <label v-if="props.icon" :for="id" class="group-text icon" :title="hintText">
            <span :class="props.icon" aria-hidden="true"></span>
        </label>

        <label v-if="props.labelBeforeTx" :for="id" class="group-text text-upper-1st" >{{tx.t(props.labelBeforeTx)}}</label>

        <select class='select'
           :value="props.modelValue"
           @input="updateValue"
           :id="id"
           v-bind="$attrs"
           >
           <option v-if="placeholderTx"  value="" style="display:none">{{tx.t(placeholderTx)}}</option>
           <option v-if="nullTx"  value=""  > - {{tx.t(nullTx)}} ? - </option>
           <option v-if="!(null===allValue || undefined===allValue)"  :value="allValue" >{{tx.t(allTx)}}</option>
           <slot/>
        <option v-for="item in items" :key="item.id" :value="item.id">
            {{labelInside}}{{ item.name }}
        </option>
    </select>
        <label v-if="labelAfterTx" :for="id" class="group-text  " >{{tx.t(labelAfterTx)}}</label>
        <slot name="after"/>
    </fieldset>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>
<script setup>
import ui from '@/core/app/ui.js';
import { ref } from 'vue';
import tx from '@/core/app/tx';
import app from '@/core/app/app';
import { stringLiteral } from '@babel/types';
import { computed } from '@vue/reactivity';


const props = defineProps({
    modelValue: null,
    placeholderTx: String,
    nullTx: String,
    labelTx:String,
    labelBeforeTx:String,
    labelAfterTx:String,
    labelInside:String,
    items:Object,
    fieldsetCss:String,
    icon:String,
    id:String,
    allValue:null,
    allTx:{type:String, default:"All"}
});



const id=ref(props.id?props.id:ui.getUid());

const emit = defineEmits(['update:modelValue'])

function updateValue (event) {
    emit('update:modelValue', event.target.value)
}

const fieldsetCssClass= computed(() => {
    let out = props.fieldsetCss;
    if (props.icon || props.labelBeforeTx || props.labelAferTx)
        out += ' group-input';
    return out;
})

const hintText = computed(() => {
    if (props.nullTx) return tx.t(props.nullTx);
    if (props.placeholderTx) return tx.t(props.placeholderTx);
    if (props.labelTx)       return tx.t(props.labelTx);
    if (props.labelBeforeTx) return tx.t(props.labelBeforeTx);
    if (props.labelAfterTx)  return tx.t(props.labelAfterTx);
    return '';
})



</script>