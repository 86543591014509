<template>
  <component :is="getLayout">
      <router-view />
  </component>
  <AcpToasts/>
</template>

<script>
import { computed, provide } from "vue";
import { useRoute } from "vue-router";
import PrivateLayout from "@/layouts/PrivateLayout.vue";
import PublicLayout from "@/layouts/PublicLayout.vue";

import AcpToasts from "./components/acp/AcpToasts.vue"


export default ({
  components: { PrivateLayout, PublicLayout , AcpToasts},
  setup() {
    const route  = useRoute();
    const getLayout = computed(()=> (route.meta.layout || 'private')+'-layout');
    return {
      getLayout

    }
  }
})
</script>

<style>

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
