<template>
    <div class="dropdown-right">
        <button class="topbar-button gap-m" @focus="ui.dropdownFocus" @click="ui.dropdownClick">
            <div class="text-uppercase font-500 visible_sd">{{ app.state.lang }}</div>

            <span v-if="app.state.colorTheme=='light'" class="icon ti-shine visible_sd" ></span>
            <span v-else class="icon fa fa-moon-o visible_sd"></span>
            <!-- <span class="icon ti-user"></span> -->
            <span class="font-600 text-primary">{{ app.state.username }}</span>
        </button>
        <div class="dropdown-content  " @click="ui.blurActiveElement">

            <button @click="logOut" class="dropdown-button" id="button-logout">
                <span class="icon ti-power-off"></span>
                <span>{{ tx.t('Log Out') }}</span>
            </button>
            <router-link to="/change-password" class="dropdown-button">
                <span class="icon ti-unlock"></span>
                <span>{{ tx.t('Change password') }}</span>
            </router-link>


            <div class='dropdown-spacer'></div>
            <button @click="app.setColorTheme('light')"  class="dropdown-button" :class="{'active':app.state.colorTheme=='light'}">
                <span class="icon ti-shine"></span>
                <span>{{ tx.t('Light theme') }}</span>
            </button>
            <button @click="app.setColorTheme('dark')" class="dropdown-button" :class="{'active':app.state.colorTheme=='dark'}">
                <span class="icon fa fa-moon-o"></span>
                <span>{{ tx.t('Dark theme') }}</span>
            </button>

            <div class='dropdown-spacer'></div>
            <button @click="app.setFontTheme('14px')" class="dropdown-button" :class="{'active':app.state.fontTheme=='14px'}">
                <span class="icon font-600"><span style="font-size:14px">aA</span></span>
                <span><span style="font-size:14px">{{ tx.t('Small text') }}</span></span>
            </button>
            <button @click="app.setFontTheme('16px')" class="dropdown-button" :class="{'active':app.state.fontTheme=='16px'}">
                <span class="icon font-600"><span style="font-size:16px">aA</span></span>
                <span><span style="font-size:16px">{{ tx.t('Large text') }}</span></span>
            </button>

            <div class='dropdown-spacer'></div>
            <button v-for="(langName, langCode) of cfg.langs" :key="langCode" class="dropdown-button"
                :class="{ 'active': langCode == app.state.lang }" type="button" @click="app.setLang(langCode)">
                <span class="icon text-uppercase font-600">{{ langCode }}</span>
                <span>{{ langName }}</span>
            </button>

        </div>
    </div>
</template>

<script setup>
import app from '@/core/app/app';
import tx from '@/core/app/tx';
import cfg from '@/app/cfg';
import ui from '@/core/app/ui';
import auth from '@/core/app/auth';
import { useRouter } from 'vue-router';

const router = useRouter();

const logOut = () => {
    auth.logOut();
    router.push({ name: "login" });
}

</script>

