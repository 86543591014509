import tx from "@/core/app/tx";

const items = [
    {id: 10, nameTx: 'Cash', name: 'Cash'},
    {id: 20, nameTx: 'Card', name: 'Card'},
    {id: 30, nameTx: 'Web', name: 'Web'},
    {id: 40, nameTx: 'Teller', name: 'Teller'},
    {id: 50, nameTx: 'Bank', name: 'Bank'},
];

const itemsForOperators = [
    {id: 10, nameTx: 'Cash', name: 'Cash'},
    {id: 20, nameTx: 'Card', name: 'Card'},
    {id: 50, nameTx: 'Bank', name: 'Bank'},
];
const itemsForSuper = [
    {id: 10, nameTx: 'Cash', name: 'Cash'},
    {id: 20, nameTx: 'Card', name: 'Card'},
    {id: 50, nameTx: 'Bank', name: 'Bank'},
    {id: -1,nameTx: 'Paid', name: 'Paid'}
];


const values = Object.freeze({
    CASH: 10,
    CARD: 20,
    WEB: 30,
    TELLER: 40,
    BANK: 50,
});


function getName(paymentMethodId) {
    switch (paymentMethodId) {
        case values.CASH:
        case "10"
        :
            return items[0].name;
        case values.CARD     :
        case "20":
            return items[1].name;
        case values.WEB      :
        case "30":
            return items[2].name;

        case values.TELLER   :
        case "40":
            return items[3].name;
        case values.BANK     :
        case "50":
            return items[4].name;

        default:
            return paymentMethodId;
    }
}

function translateNames() {
    for (let item of items) {
        item.name = tx.t(item.nameTx);
    }

    for (let item of itemsForOperators) {
        item.name = tx.t(item.nameTx);
    }
}

function getItems() {
    return items
}

function getItemsForOperators() {
    return itemsForOperators;
}
function getItemsForSuper() {
    return itemsForSuper;
}


export default {
    getItems, getItemsForOperators,getItemsForSuper, getName, translateNames, values
}



