<template>
    <div  id='acp-toasts' @toast="onNewToast">
    <TransitionGroup name="toast-list" tag="div">
        <div v-for='(msg,index)  in messages' :key="msg.key"
        class="acp-toast " :class="msg.type" @click="removeItem(index)">
            <div class="row gap-m align-items-center">
                 <div class='icon' :class="msg.icon"></div>&nbsp;
                <div class="text">{{msg.text}}</div>
                <div v-if="msg.subtext" class="subtext">{{msg.subtext}}</div>
            </div>
            <div class='icon' ><span class="ti-close"></span></div>
        </div>
    </TransitionGroup>
    </div>
</template>
<script  setup>
import { ref } from 'vue';
    const toast_duration = 5000;
    const messages = ref([
       // {       key:1, type:'danger',  icon:'ti-check', text:'Texto texto texto '},//
    ]);

    const removeItem = (index)  => {
        messages.value.splice(index,1);
    }

    let timer = null;

    const removeOldest = ()  => {
        if (messages.value.length<1)
            return;
        messages.value.splice(0,1);
        setTimer();

    }


    const setTimer =( ) => {
        if (timer)
            clearTimeout(timer);

        timer=setTimeout(removeOldest,toast_duration );
    }

    const onNewToast =(event)=>
    {
        setTimer();
        messages.value.push(event.detail)
    }

    setTimer();
</script>

<style>
.toast-list-move, /* apply transition to moving elements */
.toast-list-enter-active,
.toast-list-leave-active {
  transition: all 0.5s ease;
}

.toast-list-enter-from,
.toast-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.toast-list-leave-active {
    position: absolute;
}

.subtext {
    background: var(--color-750);
    color: var(--color-100);
    border-radius: 10px;
    font-size: 90%;
    padding: 2px 8px;
}
</style>