const cfg = {
    server: 'mallorca-sql.bankoi.biz',
    apiURL: "https://mallorca-sql.bankoi.biz/api",
    filesHost: "https://mallorca-sql.bankoi.biz",
    langs: {es: 'Español', pt: 'Português', en: 'English', de: 'Deutsch'},
    locales: {es: 'es-ES', pt: 'pt-PT', en: 'en-GB', de: 'de-DE'},
    //langs Full: {es:'Español', pt:'Português', gl:'Galego', ca:'Català',  en:'English', de:'Deutsch'},
    //locales Full: {es:'es-ES', pt:'pt-PT', gl:'gl-ES', ca:'ca-ES',  en:'en-GB', de:'de-DE'},

    // publicLangs: [{id: 'es', name: 'Español'}],
    publicLangs: [
        {id: 'es', name: 'Español'},
        {id: 'de', name: 'Deutsch'},
        {id: 'en', name: 'English'},
        {id: 'pt', name: 'Português'},

    ],
    publicDefaultLang: 'es',

    defaultLang: 'es',
    colorTheme: 'light',
    fontTheme: '14px',
    listRowCount: 10
}

export default cfg;
