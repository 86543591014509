<template>
    <fieldset  :lang="app.state.lang" :class="fieldsetCssClass" >
        <label v-if="props.labelTx"   :for="id"   class="label-s text-upper-1st">{{tx.t(props.labelTx) }}</label>

        <label v-if="props.icon" :for="id" class="group-text icon" :title="hintText">
            <span :class="props.icon" aria-hidden="true"></span>
        </label>

        <label v-if="props.labelBeforeTx" :for="id" class="group-text text-upper-1st" :class="props.labelBeforeClass" >{{tx.t(props.labelBeforeTx)}}</label>

        <input  class='input'
           :value="props.modelValue"
           @input="updateValue"
           :id="id"
           :placeholder="hintText"
           v-bind="$attrs"
           >
        <label v-if="props.labelAfterTx" :for="id" class="group-text  "  :class="props.labelAfterClass">{{tx.t(props.labelAfterTx)}}</label>
        <slot />
    </fieldset>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>
<script setup>
import ui from '@/core/app/ui.js';
import { ref } from 'vue';
import tx from '@/core/app/tx';
import app from '@/core/app/app';
import { computed } from '@vue/reactivity';


const props = defineProps({
    modelValue: null,
    placeholderTx: String,
    labelTx:String,
    labelBeforeTx:String,
    labelAfterTx:String,
    labelBeforeClass:String,
    labelAfterClass:String,
    fieldsetCss:{String, default:''},
    icon:String,
    id:String
});

const id=ref(props.id?props.id:ui.getUid());

const emit = defineEmits(['update:modelValue'])

function updateValue (event) {

    emit('update:modelValue',event.target.value)
}

const fieldsetCssClass= computed(() => {
    let out = props.fieldsetCss??'';
    if (props.icon || props.labelBeforeTx || props.labelAfterTx)
        out += ' group-input';
    return out;
})

const hintText = computed(() => {
    if (props.placeholderTx) return tx.t(props.placeholderTx);
    if (props.labelTx)       return tx.t(props.labelTx);
    if (props.labelBeforeTx) return tx.t(props.labelBeforeTx);
    if (props.labelAfterTx)  return tx.t(props.labelAfterTx);
    return '';
})



</script>