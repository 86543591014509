<template>
  <template v-if="app.state.sessionReady" >
    <div id='acp-layout' class="desktop-menu" v-if="auth.isLoggedIn()"   >
        <TopBar />
        <MainMenu/>
        <div id="acp-main"  class="cute-scroll-bar">
           <slot/>
        </div>
    </div>
  </template>
  <template v-else></template>
</template>


<script setup>
import { ref } from 'vue';
import MainMenu from '@/core/menus/MainMenu.vue'
import TopBar from '@/core/menus/TopBar.vue';
import { useRouter } from 'vue-router';
import auth from '@/core/app/auth'
import app from '@/core/app/app';

const isReady = ref(false);

const router = useRouter();

if (!auth.hasTokenStored())
    goToLoginPage();



/*
app.initPrivateSession().then (()=>{
  if (!auth.isLoggedIn()) {

    goToLoginPage();
    return;
  }

  isReady.value=true;
});
*/

function goToLoginPage() {
  router.push({name:"login"});
}


</script>